

function Mentions() {
    return (
        <main>
            Mentions légales
        </main>
    )
};

export default Mentions;