import React from "react";
import NavBar from "../Navbar";

function Header() {
    return (
        <header>
            <NavBar />
        </header>
    )
};

export default Header;
